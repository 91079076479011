import React, { useState } from "react";
import axios from "axios";
import anime from "animejs/lib/anime.es.js";

import Lua from "../images/Lua_Preto.inline.svg";
import AppearingMoon from "../components/AppearingMoon.js";

import PhoneIcon from "@material-ui/icons/Phone";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendResult, setSendResult] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    let animation = anime({
      targets: "#lua",
      rotate: "-360deg",
      loop: true,
      duration: 1700,
      easing: "easeInOutElastic(1, .5)",
    });

    axios
      .post(
        "https://nw-sendemail.azurewebsites.net/api/HttpTriggerSendEmail?code=aZh8RtaGAOho8Xv1z9KnjNaUw9XalNwDnIChjJnpu9NQzSsAOPr5TA==",
        {
          nome: name,
          email: email,
          mensagem: message,
        }
      )
      .then(function (response) {
        console.log(response);
        animation.restart();
        animation.pause();
        setLoading(false);
        setSendResult("Enviado com sucesso!");
      })
      .catch(function (error) {
        console.log(error);
        animation.restart();
        animation.pause();
        setLoading(false);
        setSendResult("Erro! Tente novamente mais tarde!");
      });
  };

  return (
    <section className="contact" id="contact">
      <h2>Contato</h2>
      <Lua className="background-moon" />
      <div className="contact-content">
        <p className="call-text">
          Quer falar conosco? <br /> Mande uma mensagem no formulário ou
          contate-nos por um dos meios abaixo.
        </p>
        <div className="contact-options">
          <p className="info">
            (61) 9 9955-2833 <SmartphoneIcon />
          </p>
          <p className="info">
            (61) 3242-0269 <PhoneIcon />
          </p>
          <a href="mailto:contato@novaweb.mobi" className="info">
            contato@novaweb.mobi <EmailIcon />
          </a>
          <a href="https://www.linkedin.com/company/nova-web/" className="info">
            nova-web/ <LinkedInIcon />
          </a>
        </div>
        <form onSubmit={sendEmail}>
          <label htmlFor="name">
            Nome
            <input
              type="text"
              placeholder="Digite seu nome"
              id="name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
              className={name ? "filled" : ""}
            />
          </label>
          <label htmlFor="email">
            Email
            <input
              type="text"
              placeholder="Digite seu email"
              id="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className={email ? "filled" : ""}
            />
          </label>
          <label htmlFor="message">
            Mensagem
            <textarea
              type="text"
              placeholder="Digite sua mensagem"
              id="message"
              value={message}
              required
              onChange={(e) => setMessage(e.target.value)}
              className={message ? "filled" : ""}
              maxLength={800}
            />
          </label>
          <div className="send-div">
            <p className="send-message">{sendResult ?? ""}</p>
            <button type="submit" disabled={loading}>
              <AppearingMoon />
              {loading ? "ENVIANDO" : "ENVIAR"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
