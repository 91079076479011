import React from "react";

import Detail from "../images/White_Bg_Detail.inline.svg";
import LogoOriginal from "../images/Logo_Original.svg.js";

function AboutUs() {
  // const [selected, setSelected] = useState(0);

  const imageBlobs = [<LogoOriginal className="image-logo" />];

  return (
    <section className="about-us" id="about-us">
      <Detail className="bg-detail" />
      <h2>Sobre Nós</h2>
      <div className="content">
        <p className="text-content">
          A Nova Web é um fabricante e integrador de tecnologias com
          experiência em implantação de ambientes críticos de tecnologia de
          grandes empresas, entre bancos, indústrias, sites de e-commerce,
          governo, montadoras, hospitais e diversas outras.
          <br /> <br />
          Contamos com uma rede de parceiros de negócios, design, e tecnologias que associados aos nossos produtos e serviços, oferecem amplo portfólio de soluções que visam a otimização dos custos operacionais, segurança e defesa do ambiente e ganho de produção dos nossos clientes.
          <br /> <br />
          O nosso compromisso é com a <b>transparência</b>, <b>inovação</b>, <b>inteligência</b> e <b>entrega perfeita</b>, ligados à satisfação do cliente e geração de valor.
          <br /> <br />A Nova Web mantém uma área de Pesquisa & Desenvolvimento
          para criação de tecnologias para ajudar as pessoas e empresas
          produzirem riquezas, bem-estar e benefícios para toda a sociedade.
        </p>
        {imageBlobs[0]}
        {/* <div className="display-buttons">
          <button
            type="button"
            className={`display-button left ${
              selected === 0 ? "selected" : ""
            }`}
            aria-label="Change image displaying"
            onClick={() => setSelected(0)}
          >
            1
          </button>
          <button
            type="button"
            aria-label="Change image displaying"
            className={`display-button ${selected === 1 ? "selected" : ""}`}
            onClick={() => setSelected(1)}
          >
            2
          </button>
          <button
            type="button"
            aria-label="Change image displaying"
            className={`display-button ${selected === 2 ? "selected" : ""}`}
            onClick={() => setSelected(2)}
          >
            3
          </button>
          <button
            type="button"
            aria-label="Change image displaying"
            className={`display-button right ${
              selected === 3 ? "selected" : ""
            }`}
            onClick={() => setSelected(3)}
          >
            4
          </button>
        </div> */}
      </div>
    </section>
  );
}

export default AboutUs;
