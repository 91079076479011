import React from "react";

function ProductCard({ logo, title, text, link }) {
  return (
    <div className="product-card">
      <img src={logo} alt={`Logo ${title}`} />
      <h4 className="title">{title}</h4>
      <p className="description">{text}</p>
      {link ? (
        <a href={link} className="see-more">
          Ver mais
        </a>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductCard;
