import React from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-scroll";
import Lua from "../images/Lua_Preto.inline.svg";

function Presentation() {
  return (
    <div className="presentation" id="presentation">
      <Lua className="bg-lua" />
      <h1>
        Soluções <span className="highlight">inteligentes</span>
        <br />
        para novos desafios.
      </h1>
      <Link
        to="solutions"
        spy={true}
        smooth={true}
        offset={10}
        duration={1000}
        className="down-link"
      >
        <KeyboardArrowDownIcon className="down-icon" />
      </Link>
    </div>
  );
}

export default Presentation;
