import React, { useState } from "react";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Curve from "../images/Solutions_Curve.inline.svg";
import Web from "../images/Solutions_Family.inline.svg";
import Mobile from "../images/Solutions_Mobile.inline.svg";
import IoT from "../images/Solutions_IoT.inline.svg";
import TechConsulting from "../images/Solutions_Web.inline.svg";
import Security from "../images/Solutions_Security.inline.svg";

function Solutions() {
  const [current, setCurrent] = useState(0);
  const contents = [
    {
      svg: <Web className="solutions-image" />,
      title: "No que acreditamos",
      text:
        "Nós acreditamos que a educação é o melhor elo que você pode criar com o seu cliente e colaborador. Quando você ensina algo para alguém, para sempre ele irá procurá-lo para todas as dúvidas no seu segmento. Nós acreditamos que a educação, em conjunto a transparência e honestidade, são as bases de qualquer relação humana.",
    },
    {
      svg: <Mobile className="solutions-image" />,
      title: "A sua empresa nunca fecha",
      text:
        "Criamos aplicativos e sistemas customizados de acordo com o seu produto, com o seu estilo e forma de comunicação. Além dos celulares e desktops, o seu produto pode estar nos smartwatches Apple® e Android®, nas Smart TVs, Alexa®, Google Home®, Apple TV® e Chrome Cast®.",
    },
    {
      svg: <TechConsulting className="solutions-image" />,
      title: "Consultoria em Tecnologia",
      text:
        "Ajudamos você no processo de decisão da melhor tecnologia e solução para o seu negócio.\n\nAjudamos as organizações a tomar decisões e a implementar soluções de tecnologia que agregam valor aos seus processos e operações.",
    },
    {
      svg: <IoT className="solutions-image" />,
      title: "Internet das Coisas (IoT)",
      text:
        "Desenvolvemos e fabricamos dispositivos IoT customizados para sua empresa, fazenda, condomínio e residência. Integramos toda a rede IoT permitindo gestão centralizada com os principais provedores de nuvem do mercado mundial.",
    },
    {
      svg: <Security className="solutions-image" />,
      title: "Segurança da Informação",
      text:
        "A segurança da informação desempenha um papel crucial no ambiente de negócios moderno. Ela é essencial para proteger dados sensíveis, informações confidenciais e ativos digitais, garantindo que a organização esteja em conformidade com regulamentações e padrões de segurança.",
    },
  ];

  // Função para voltar na ordem (0 -> 5 -> 4 -> 3...)
  const previous = () => {
    setCurrent((c) => (c + (contents.length - 1)) % contents.length);
  };

  // Função para ir na ordem (0 -> 1 -> 2 -> 3...)
  const next = () => {
    setCurrent((c) => (c + 1) % contents.length);
  };

  return (
    <section id="solutions" className="solutions">
      <h2>Transformação Digital</h2>
      {contents[current].svg}
      <div className="text-container">
        <ChevronLeftIcon className="chevron" onClick={() => previous()} />
        <h4>{contents[current].title}</h4>
        <p>{contents[current].text}</p>
        <ChevronRightIcon className="chevron" onClick={() => next()} />
      </div>
      <Curve className="curve" />
    </section>
  );
}

export default Solutions;
