import React, { useState } from "react";

import BlockchainBlob from "../images/Tech_Blockchain.inline.svg";
import FaceBlob from "../images/Tech_Face.inline.svg";
import WebBlob from "../images/Tech_Web.inline.svg";
import ArtificialBlob from "../images/Tech_Artificial.inline.svg";

import BlockchainIcon from "../images/Icon_Blockchain.inline.svg";
import FaceIcon from "../images/Icon_Face.inline.svg";
import WebIcon from "../images/Icon_Web.inline.svg";
import ArtificialIcon from "../images/Icon_Artificial.inline.svg";

function Technologies() {
  const [selected, setSelected] = useState(0);

  const techBlobs = [
    <WebBlob className="blob-image" />,
    <FaceBlob className="blob-image" />,
    <BlockchainBlob className="blob-image" />,
    <ArtificialBlob className="blob-image" />,
  ];
  const techTitles = [
    "Aplicativos e\n softwares sob medida",
    "Inteligência Artificial\nintegrada à sua empresa",
    "Automação de processos",
    "Implantação de tecnologia",
  ];
  const techDescriptions = [
    "Desenvolvemos aplicativos e softwares customizados para todas as necessidades, ambientes e plataformas. Oferecemos prova de conceito.\n\nAcompanhe a evolução do seu projeto com acesso em tempo real ao código fonte e toda documentação gerada. ",
    "Integramos a inteligência artificial em todos os seus processos e nas soluções de tecnologia que você já utiliza na sua empresa.\n\nAumente a produtividade, reduza custos e principalmente utilize a inteligência artificial a favor dos seus clientes e do seu negócio.",
    "A automação elimina tarefas manuais demoradas e propensas a erros, acelerando processos e aumentando a eficiência. Isso permite que as empresas façam mais em menos tempo.\n\nEmpresas que adotam a automação frequentemente se tornam mais competitivas, pois podem entregar produtos e serviços mais rapidamente e a um custo menor.",
    "Ajudamos você na implantação adequada da melhor tecnologia e solução para o seu negócio.\n\nEstamos aqui para ser o catalisador das suas aspirações, capacitando as organizações a tomar decisões informadas e a abraçar soluções tecnológicas que não apenas otimizam processos, mas também acrescentam um toque humano à evolução dos seus negócios.",
  ];

  return (
    <section id="technologies" className="technologies">
      <h2>Serviços</h2>
      <div className="techs-container">
        <h3>{techTitles[selected]}</h3>
        <p>{techDescriptions[selected]}</p>
        <div className="techs-display">
          {techBlobs[selected]}
          <div className="display-buttons">
            <button
              type="button"
              className={`display-button left ${
                selected === 0 ? "selected" : ""
              }`}
              aria-label="Change tech displaying"
              onClick={() => setSelected(0)}>
              <WebIcon />
            </button>
            <button
              type="button"
              aria-label="Change tech displaying"
              className={`display-button ${selected === 1 ? "selected" : ""}`}
              onClick={() => setSelected(1)}>
              <FaceIcon />
            </button>
            <button
              type="button"
              aria-label="Change tech displaying"
              className={`display-button ${selected === 2 ? "selected" : ""}`}
              onClick={() => setSelected(2)}>
              <BlockchainIcon />
            </button>
            <button
              type="button"
              aria-label="Change tech displaying"
              className={`display-button right ${
                selected === 3 ? "selected" : ""
              }`}
              onClick={() => setSelected(3)}>
              <ArtificialIcon />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Technologies;
