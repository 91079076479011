import React, { useState } from "react";
import logo from "../images/Logo_Branco_Amarelo.png";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import scrollTo from "gatsby-plugin-smoothscroll";

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header className="header">
      <img src={logo} alt="NovaWeb Logo" />
      <div className={openMenu ? "menu open" : "menu"}>
        {openMenu ? (
          <CloseIcon onClick={() => setOpenMenu(!openMenu)} />
        ) : (
          <MenuIcon onClick={() => setOpenMenu(!openMenu)} />
        )}
        {openMenu ? (
          <ul>
            <li
              onKeyDown={() => scrollTo("#presentation")}
              onClick={() => scrollTo("#presentation")}
              role="presentation"
            >
              Início
            </li>
            <li
              onKeyDown={() => scrollTo("#solutions")}
              onClick={() => scrollTo("#solutions")}
              role="presentation"
            >
              Transformação
            </li>
            <li
              onKeyDown={() => scrollTo("#technologies")}
              onClick={() => scrollTo("#technologies")}
              role="presentation"
            >
              Soluções
            </li>
            <li
              onKeyDown={() => scrollTo("#products")}
              onClick={() => scrollTo("#products")}
              role="presentation"
            >
              Produtos
            </li>
            <li
              onKeyDown={() => scrollTo("#about-us")}
              onClick={() => scrollTo("#about-us")}
              role="presentation"
            >
              Sobre Nós
            </li>
            <li
              onKeyDown={() => scrollTo("#contact")}
              onClick={() => scrollTo("#contact")}
              role="presentation"
            >
              Contato
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    </header>
  );
}

export default Header;
