import React from "react";
import { Link } from "gatsby";
import logo from "../images/Logo_Branco_Amarelo.png";

function Footer() {
  return (
    <footer className="footer">
      <img src={logo} alt="Logo NovaWeb" />
      <div className="support">
        <span>Precisa de suporte? Acesse:</span>
        <a
          href="https://suporte.novaweb.mobi"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://suporte.novaweb.mobi
        </a>
      </div>
      <div className="links">
        <Link to="/politicadeprivacidade">Política de Privacidade</Link>
      </div>
    </footer>
  );
}

export default Footer;
