import * as React from "react";
import { Helmet } from "react-helmet";

import "../scss/App.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Presentation from "../sections/Presentation";
import Solutions from "../sections/Solutions";
import Technologies from "../sections/Technologies";
import Products from "../sections/Products";
import AboutUs from "../sections/AboutUs";
import Contact from "../sections/Contact";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Nova Web</title>
        <meta
          name="description"
          content={"Soluções inteligentes para novos desafios."}
        />
      </Helmet>
      <Header theme="dark" />
      <Presentation />
      <Solutions />
      <Technologies />
      <Products />
      <AboutUs />
      <Contact />
      <Footer />
    </main>
  );
};

export default IndexPage;
