import React from "react";

import ScrollContainer from "react-indiana-drag-scroll";

import ProductCard from "../components/ProductCard";
import LogoSmartView from "../images/smartview.png";
import LogoNovaID from "../images/security-scan.png";
import LogoLiveness from "../images/liveness.png";
import LogoVotofone from "../images/votofone.png";
import LogoTwiggy from "../images/twiggy.svg";
import LogoAPI from "../images/LogoApi.svg";
import LogoIoT from "../images/iot2.png";

function Products() {
  return (
    <section className="products" id="products">
      <div className="darker-bg"></div>
      <h2>Produtos</h2>
      <ScrollContainer
        className="scroll-container"
        hideScrollbars={false}
        vertical={false}
      >
        <div className="product-cards">
          <ProductCard
            title="Reconhecimento Facial"
            logo={LogoTwiggy}
            text={
              "Solução de alta performance que consegue detectar, identificar e monitorar faces humanas em praticamente todas as condições de visibilidade."
            }
            link=""
          />
          <ProductCard
            title="Internet das Coisas"
            logo={LogoIoT}
            text={
              "Desenvolvemos e fabricamos dispositivos IoT customizados para a sua empresa, fazenda, galpão, condomínio e residência."
            }
            link=""
          />
          <ProductCard
            title="Banco Digital e Pagamentos"
            logo={LogoAPI}
            text={
              "Implantação de banco digital para o seu ecossistema de clientes e parceiros. Coloque a sua marca nas mãos dos seus clientes e parceiros com produtos e serviços diferenciados."
            }
            link=""
          />
          <ProductCard
            title="Voto Fone®"
            logo={LogoVotofone}
            text={
              "O sistema Voto Fone® permite a realização de eleições online sem limite de eleitores e candidatos ou chapas inscritas."
            }
            link=""
          />
          <ProductCard
            title="Nova ID®"
            logo={LogoNovaID}
            text={
              "O Nova ID® é um servidor de autenticação que pode ser utilizado como único fator de autenticação ou como múltiplos fatores de autenticação (MFA).\n\nÉ 100% compatível com o protocolo SAML  (Security Assertion Markup Language) que torna o Nova ID® um provedor de identidade (IdP) para aplicações ou sistemas que utilizam o SAML Single Sign On (SSO)."
            }
            link=""
          />
          <ProductCard
            title="Smart View®"
            logo={LogoSmartView}
            text={
              "O Smart View® é uma plataforma de software para gestão, visualização e análise de dados e correlação de eventos."
            }
            link="/smartview"
          />
          <div></div>
        </div>
      </ScrollContainer>
    </section>
  );
}

export default Products;
